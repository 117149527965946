import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
// styles
const pageStyles = {
  color: "#ddd",
  height: "500px",
  position: "relative",
};

const myHead = {
  fontSize: "3.4em",
  fontWeight: "bolder",
};

const verticalCenter = {
  margin: 0,
  position: "absolute",
  top: "50%",
  msTransform: "translateY(-50%)",
  transform: "translateY(-50%)",
  textAlign: "center",
  width: "100%",
};

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | 404 Not Found`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | 404 Not Found`}
        />

        <title>{`${process.env.GATSBY_SITE_NAME} | 404 Not Found`}</title>
      </Helmet>
      <main style={pageStyles}>
        <div style={verticalCenter}>
          <h2 style={myHead}>404</h2>
          <p>Sorry - that page doesn't exist...</p>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
